<template>
  <div class="d-flex flex-column flex-root">
    <div
      id="kt_login"
      :class="activeFormClass"
      class="login login-4 d-flex flex-row-fluid animated"
      style="min-height: 75rem"
    >
      <!--begin::Content-->
      <div
        :style="{ backgroundImage: `url(${backgroundImage})` }"
        class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
      >
        <div class="login-form text-center p-7 position-relative overflow-hidden">
          <!--begin::Login-->
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
            <a href="#">
              <img
                alt=""
                class="max-w-200px mb-n15"
                src="../../../../public/media/logos/logo-letter-4.png"
              />
            </a>
          </div>
          <!--end::Login Header-->
          <!--begin::Login Sign in form-->
          <div class="login-signin mt-20">
            <div class="mb-20">
              <h3>{{ $t("login.header") }}</h3>
              <div class="text-muted font-weight-bold">{{ $t("login.header_text") }}</div>
            </div>
            <div
              class="digital-container d-flex justify-content-center mt-n15 mb-5 align-self-center"
              v-if="passwordStatus"
            >
              <span class="single-digit justify-content-center"> {{ timerCount }} </span>
            </div>
            <b-form
              id="kt_login_signin_form"
              class="form animated animate__animated animate__backInUp"
              @submit.stop.prevent="onSubmit"
            >
              <div
                class="alert fadeIn alert-danger"
                role="alert"
                v-bind:class="{ show: errors }"
                v-if="errors"
              >
                <div class="alert-text">
                  {{ errors }}
                </div>
              </div>

              <div class="alert fadeIn alert-success" role="alert" v-if="successMessage">
                <div class="alert-text">
                  {{ successMessage }}
                </div>
              </div>

              <div class="form-group mb-5">
                <b-form-group
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <custom-multi-select
                    :item-per-row="1"
                    :max="1"
                    :model.sync="form.customer_id"
                    :not-list="true"
                    :options="customerOptions"
                    :required="true"
                    :title="$t('invoice.company_name')"
                    @save="changeCustomer($event)"
                    :placeholder-input="'Aramak istediğiniz şirketi giriniz'"
                    name="customers"
                  ></custom-multi-select>
                </b-form-group>
              </div>
              <div class="form-group mb-5">
                <b-form-group
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <b-form-input
                    id="example-input-1"
                    v-model="$v.form.phone.$model"
                    :placeholder="$t('messages.phone')"
                    :state="validateState('phone')"
                    aria-describedby="input-1-live-feedback"
                    class="form-control h-auto form-control-solid py-4 px-8 form-control-sm"
                    name="example-input-1"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    {{ $t("login.phone_error") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div v-if="passwordStatus" class="form-group mb-5">
                <b-form-group
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <b-form-input
                    id="example-input-2"
                    v-model="$v.form.password.$model"
                    :placeholder="$t('messages.password')"
                    :state="validateState('password')"
                    aria-describedby="input-2-live-feedback"
                    class="form-control h-auto form-control-solid py-4 px-8 form-control-sm"
                    name="example-input-2"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-2-live-feedback">
                    {{ $t("login.password_error") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div
                v-if="false"
                class="form-group d-flex flex-wrap justify-content-between align-items-right"
              >
                <a
                  id="kt_login_forgot"
                  class="text-muted text-hover-primary"
                  href="#"
                  @click="showForm('forgot')"
                  >{{ $t("login.forgot_password") }}
                </a>
              </div>
              <button
                id="kt_login_signin_submit"
                ref="kt_login_signin_submit"
                v-if="submitValidate"
                :disabled="+timerCount === 0"
                class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 btn-sm"
                @click="showForm('signin')"
              >
                {{ $t("messages.login") }}
              </button>

              <br />
              <button
                v-if="passwordStatus && +timerCount === 0"
                id="kt_login_send_sms"
                ref="kt_login_send_sms"
                class="btn btn-info font-weight-bold px-9 py-4 my-3 mx-4 btn-sm"
                type="button"
                @click.prevent="sendSms"
              >
                {{ $t("messages.sms_send_again") }}
              </button>
            </b-form>
          </div>
          <!--end::Login Sign in form-->
          <!--begin::Login forgot password form-->
          <div class="login-forgot">
            <div class="mb-20">
              <h3>{{ $t("login.forgot_password_header") }}</h3>
              <div class="text-muted font-weight-bold">
                {{ $t("login.forgot_password_header_text") }}
              </div>
            </div>
            <form id="kt_login_forgot_form" class="form" method="post">
              <div class="form-group mb-10">
                <input
                  :placeholder="$t('messages.phone')"
                  autocomplete="off"
                  class="form-control form-control-sm form-control-solid h-auto py-4 px-8"
                  name="phone"
                  type="text"
                />
              </div>
              <div class="form-group d-flex flex-wrap flex-center mt-10">
                <button
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2 btn-sm"
                >
                  {{ $t("messages.send") }}
                </button>
                <button
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary btn-sm font-weight-bold px-9 py-4 my-3 mx-2 btn-sm"
                  type="button"
                  @click="showForm('signin')"
                >
                  {{ $t("messages.cancel") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Login forgot password form-->
          <!--end::Login-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  LOGIN,
  LOGIN_SMS,
  LOGOUT,
  SET_SUCCESS_MESSAGE,
} from "@/core/services/store/auth.module";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import KTUtil from "@/assets/js/components/util";
import moduleUser, {
  GET_USER,
  MODULE_NAME as MODULE_USER,
} from "@/core/services/store/user.module";
import store from "@/core/services/store";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import SecureLS from "secure-ls";
import _ from "lodash";
import axios from "axios";

const ls = new SecureLS({ encodingType: "aes", isCompression: true });
const _MODULE_USER = MODULE_USER;
export default {
  mixins: [validationMixin],
  name: "login",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_USER, moduleUser);
  },
  components: {
    CustomMultiSelect,
  },
  data() {
    return {
      activeFormClass: "login-signin-on",
      passwordStatus: false,
      timerCount: 180,
      defaultTimerCount: 180,
      timerInverval: null,
      // Remove this dummy login info
      form: {
        phone: null,
        password: null,
        customer_id:null,
      },
      customers: [],
    };
  },
  validations() {
    if (this.passwordStatus) {
      return {
        form: {
          phone: {
            required,
            minLength: minLength(10),
          },
          password: {
            required,
            minLength: minLength(3),
          },
        },
      };
    }
    return {
      form: {
        phone: {
          required,
          minLength: minLength(10),
        },
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
      successMessage: (state) => state.auth.successMessage,
    }),
    loginLogo() {
      return process.env.BASE_URL + "assets/media/logos/logo-letter-13.png";
    },
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-3.jpg";
    },
    customerOptions() {
      return this.convertArrayToObjectByKey(this.customers, "id", "title");
    },
    submitValidate(){
      let status = true;
      if(this.form.customer_id==null || this.form.phone==null){
        status=false;
      }
      return status;
    }
  },
  methods: {
    showForm(id) {
      let form = "kt_login_" + id + "_form";
      this.activeFormClass = "login-" + id + "-on";
      KTUtil.animateClass(KTUtil.getById(form), "animate__animated animate__backInUp");
    },
    changeCustomer(event) {
      ls.removeAll();
      let id = event.newValue[0];
      let customer = this.customers.find((customer) => customer.id == id);
      localStorage.setItem("VUE_APP_APP_URL", customer.backend_app_url);
      localStorage.setItem("VUE_APP_BACKEND_APP_URL", customer.backend_url);
      localStorage.setItem("VUE_APP_MEDIA_URL", customer.media_url);
      localStorage.setItem("VUE_APP_NAME", customer.title);
      localStorage.setItem("VUE_APP_ID", id);

      window.location.reload();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$store.commit(SET_SUCCESS_MESSAGE, null);
      let self = this;

      if (this.passwordStatus) {
        const username = this.$v.form.phone.$model;
        const sms_code = this.$v.form.password.$model;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        // send login request
        this.$store
          .dispatch(LOGIN, { username, sms_code })
          // go to which page after successfully login
          .then((response) => {
            this.$store.dispatch(_MODULE_USER + "/" + GET_USER).then((res) => {
              if (res) {
                let firstItem = _.head(_.values(res.menu));
                if (firstItem) {
                  this.$router.push({ path: firstItem.menu.link });
                } else {
                  this.$router.push({ name: "dashboard" });
                }
              }
            });
          });

        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      } else {
        const username = this.$v.form.phone.$model;
        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // send login request
        this.$store
          .dispatch(LOGIN_SMS, { username })
          // go to which page after successfully login
          .then((response) => {
            if (response) {
              self.timerCount = self.defaultTimerCount;
              self.passwordStatus = true;

              if (self.timerInverval) {
                clearInterval(self.timerInverval);
              }

              self.timerInverval = setInterval(() => {
                if (this.timerCount > 0) {
                  this.timerCount--;
                }
              }, 1000);
            }
          });

        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      }
    },
    sendSms() {
      this.passwordStatus = false;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const username = this.$v.form.phone.$model;
      // clear existing errors
      this.$store.dispatch(LOGOUT);
      this.$store.commit(SET_SUCCESS_MESSAGE, null);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_send_sms"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      let self = this;
      // send login request
      this.$store
        .dispatch(LOGIN_SMS, { username })
        // go to which page after successfully login
        .then((response) => {
          if (response) {
            self.passwordStatus = true;
            self.timerCount = self.defaultTimerCount;

            if (self.timerInverval) {
              clearInterval(self.timerInverval);
            }

            self.timerInverval = setInterval(() => {
              if (this.timerCount > 0) {
                this.timerCount--;
              }
            }, 1000);
          }
        });

      submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
    },
    isLocal() {
      // window.location.hostname değeri "localhost" veya "127.0.0.1" ise uygulama yerelde çalışıyordur.
      return (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
      );
    },
    getCustomers() {
      let self = this;
      let apiurl = process.env.VUE_APP_BACKEND_APP_URL;
      axios.get('https://pulpopro.com/aletex-backend/public/api/customers')
        .then((response) => {
          self.customers = response.data.data;
          if (this.isLocal()) {
            self.customers.push({
              app_url: "https://localhost:8080/mobilapp/",
              backend_app_url: "http://localhost:8000/",
              backend_url: "http://localhost:8000/",
              created_at: null,
              deleted_at: null,
              id: 99,
              media_url: "http://localhost:8080/media/",
              mobile_url: "https://localhost:8080/",
              order: 1,
              title: "Localhost",
              updated_at: null,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    let localStorageCompanyId = localStorage.getItem("VUE_APP_ID");
    if (localStorageCompanyId) {
      this.form.customer_id = localStorageCompanyId;
    }
    this.getCustomers();
    this.$store.commit(SET_SUCCESS_MESSAGE, null);
    clearInterval(this.timerInverval);
    this.showForm("signin");
  },
};
</script>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/classic/login-4.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
}
.container:first-child {
  margin-left: 0;
}
.title {
  font-size: 12px;
  margin-bottom: 5px;
}
.digital-container {
  display: flex;
  flex-direction: row;
  padding: 0;
}
.single-digit {
  position: relative;
  display: flex;
  flex: 0 1 25%;
  font-size: 30px;
  background: linear-gradient(
    180deg,
    rgb(102, 199, 232) 0%,
    rgb(64, 138, 198) 35%,
    rgb(70, 41, 133) 100%
  ) !important;
  border-radius: 5px;
  padding: 10px 12px;
  color: #fff;
}
.single-digit:first-child {
  margin-right: 2px;
}
.single-digit:after {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  bottom: 50%;
  content: "";
  width: "100%";
  height: 2px;
  background-color: $primary !important;
  opacity: 0.4;
}
.timer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}
.separator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin: 0 0 10px;
}
.separator {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #404549;
  border-radius: 6px;
  margin: 5px 0;
}
.button {
  border-radius: 5;
  margin: 0 10px 0 0;
  outline: none;
  border: none;
  padding: 6px 14px;
  color: #404549;
  border-radius: 3px;
  border: solid 1px #404549;
}
.button:hover {
  box-shadow: 0 0 11px #21212133;
  cursor: pointer;
}
.container {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
.header-bg {
  background-color: #404549;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.separator {
  height: 0px;
  margin-top: 30px;
  border: dashed 2px #404549;
}
</style>
